<template>
  <div>
      <p class="alert alert-primary mt-3">
        <i class="far fa-info-circle me-2"></i>
        For a list of configurations for common providers, please see our FAQ Item: <a href="https://support.swandoola.com/faqs/configuring-email-integration-with-manual-imap-smtp-settings">Setting up IMAP / SMTP integrations</a>
      </p>

      <div class="row mb-3">
        <div class="col-6 mx-auto">
          <h5>Enter your email address below to get started:</h5>
          <input class="form-control m-auto text-center"
                 type="email"
                 v-model="emailInput"
                 placeholder="mail@example.com"/>
        </div>
      </div>


      <div class="row mb-3">
        <div class="col-6 text-align-center">
          <div class="bg-light rounded w-auto p-3 mt-3">
            <h4 class="mt-0">
              IMAP Config
              <i class="cursor-pointer far fa-edit float-end"
                 @click="editImap=!editImap"></i>
            </h4>
            <label for="host">Host</label>
            <input type="text"
                   class="form-control mb-2"
                   name="host"
                   v-model="detectedImapConfig.host"
                   :disabled="!editImap"/>

            <label for="port">Port</label>
            <input type="text"
                   class="form-control mb-2"
                   name="port"
                   v-model="detectedImapConfig.port"
                   :disabled="!editImap"/>

            <label for="encryption">Security</label>
            <select v-model="detectedImapConfig.encryption"
                    class="form-control mb-2"
                    :disabled="!editImap">
              <option value="None" :selected="detectedImapConfig.encryption == 'None'">None</option>
              <option value="ssl" :selected="detectedImapConfig.encryption == 'ssl'">SSL</option>
              <option value="tls" :selected="detectedImapConfig.encryption == 'tls'">TLS</option>
            </select>
          </div>
        </div>
        <div class="col-6 text-align-center">
          <div class="bg-light rounded w-auto p-3 mt-3">
            <h4 class="mt-0">
              SMTP Config
              <i class="cursor-pointer far fa-edit float-end"
                 @click="editSmtp=!editSmtp"></i>
            </h4>
            <label for="host">Host</label>
            <input type="text"
                   class="form-control mb-2"
                   name="host"
                   v-model="detectedSmtpConfig.host"
                   :disabled="!editSmtp"/>

            <label for="port">Port</label>
            <input type="text"
                   class="form-control mb-2"
                   name="port"
                   v-model="detectedSmtpConfig.port"
                   :disabled="!editSmtp"/>

            <label for="encryption">Security</label>
            <select v-model="detectedSmtpConfig.encryption"
                    class="form-control mb-2"
                    :disabled="!editSmtp">
              <option value="None" :selected="detectedSmtpConfig.encryption == 'None'">None</option>
              <option value="ssl" :selected="detectedSmtpConfig.encryption == 'ssl'">SSL</option>
              <option value="tls" :selected="detectedSmtpConfig.encryption == 'tls'">TLS</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-3" v-if="imapValid && smtpValid">
        <div class="col-8 mx-auto text-center">
          <h5>Enter your email password to verify authentication:</h5>
          <input type="password"
                 placeholder="Email Password"
                 class="form-control text-center mx-auto"
                 v-model="passwordInput"/>
          <button
                  v-if="testSuccess !== true"
                  class="btn btn-primary mx-auto d-block mt-3"
                  @click="testService"
                  :disabled="busy">
            <busy :visible="busy" button />
            Test Authentication
          </button>
        </div>
      </div>

      <div class="row mb-3" v-if="testSuccess !== null">
        <div class="col-4 mx-auto text-center">
          <span>
            <i class="fad"
               :class="imapTestSucess ? 'fa-check-circle text-primary' : 'fa-times-circle text-danger'"></i>
            <span>IMAP Authentication</span>
          </span>
          <br />
          <span>
            <i class="fad"
               :class="smtpTestSuccess ? 'fa-check-circle text-primary' : 'fa-times-circle text-danger'"></i>
            <span>SMTP Authentication</span>
          </span>
        </div>
      </div>
  </div>
</template>



<script>
export default {
  props: [],
  data() {
    return {
      emailInput: "",
      passwordInput: "",
      busy: false,
      detectedImapConfig: {
        host: "",
        port: "",
        encryption: "",
      },
      detectedSmtpConfig: {
        host: "",
        port: "",
        encryption: "",
      },
      testSuccess: null,
      imapTestSucess: null,
      smtpTestSuccess: null,
      editImap: true,
      editSmtp: true,
      useSmtp: false,
    };
  },
  methods: {
    testService(){
      this.busy = true;
      this.$axios.post(process.env.VUE_APP_API_URL + '/settings/integrations/test-mail-service', {
        email: this.emailInput,
        imap_config: this.detectedImapConfig,
        smtp_config: this.detectedSmtpConfig,
        password: this.passwordInput,
      }).then(({data}) => {
        this.$EventBus.$emit("alert", data);
        this.imapTestSucess = data.imap_success;
        this.smtpTestSuccess = data.smtp_success;
        this.testSuccess = (this.imapTestSucess && this.smtpTestSuccess);
        if (!this.imapTestSucess){
          this.editImap = true;
        }
        if (!this.smtpTestSuccess){
          this.editSmtp = true;
        }
        if (this.testSuccess){
          this.editImap = false;
          this.editSmtp = false;
          this.saveService();
        }
        this.busy = false;
      });
    },
    saveService(){
      this.busy = true;
      this.$axios.post(process.env.VUE_APP_API_URL + '/settings/integrations/save-mail-service', {
        email: this.emailInput,
        imap_config: this.detectedImapConfig,
        smtp_config: this.detectedSmtpConfig,
        password: this.passwordInput,
      }).then(({data}) => {
        this.$EventBus.$emit("alert", data);
        this.busy = false;
        window.location = '/settings/integrations';
      });
    },
    goToUrl(url){
      window.location = url;
    }
  },
  computed: {
    smtpValid() {
      return (
              this.detectedSmtpConfig.host.length > 0 &&
              this.detectedSmtpConfig.port.length > 0 &&
              this.detectedSmtpConfig.encryption.length > 0)
    },
    imapValid() {
      return (
              this.detectedImapConfig.host.length > 0 &&
              this.detectedImapConfig.port.length > 0 &&
              this.detectedImapConfig.encryption.length > 0)
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  mounted() {
  },
  components: {
  }
};
</script>

<style>
</style>
