<template>
  <div>
      <img src="@/assets/images/providers/google/btn_google_signin_dark_normal_web.png"
           class="cursor-pointer"
           @click="signInWithGoogle" />
  </div>
</template>



<script>
export default {
  props: ["user"],
  data() {
    return {
    };
  },
  methods: {
    async signInWithGoogle(){
      await this.$google.api.auth2.getAuthInstance().signIn();
      this.$emit("closed");
    },
  },
  computed: {
  },
  mounted() {
  },
  components: {
  }
};
</script>

<style>
</style>
