<template>
  <div>
    <busy :visible="loading" />
    <div v-if="!loading">
      <p>
        Connect with your external email service from here, this will fetch
        emails related to your clients and attach them to the relevant client
        profile. It also enables you to send emails directly from Swandoola
        whilst keeping a copy in your inbox.
      </p>
      <div v-if="(!this.$stash.gmail.signedIn && emailIntegrationConfigs.length === 0) || showAdd">
        <email-setup :user="user" @closed="showAdd=false"></email-setup>
        <button
          v-if="showAdd"
          @click="showAdd = false"
          class="btn btn-sm btn-light float-end"
        >
          Cancel
        </button>
      </div>
      <div v-else>
        <div class="card bg-light">
          <div class="card-body">
            <div class="card mb-3">
              <table class="table table-hover mb-0">
                <thead>
                  <th scope="col" style="border-top: 0px">Provider</th>
                  <th scope="col" style="border-top: 0px">Email</th>
                  <th scope="col" style="border-top: 0px">Status</th>
                  <th scope="col" style="border-top: 0px"></th>
                </thead>
                <tbody>
                  <tr v-if="$stash.gmail.signedIn">
                    <td>Gmail</td>
                    <td>{{ $stash.gmail.emailAddress }}</td>
                    <td>
                      <span class="badge bg-primary">
                        Active
                      </span>
                    </td>
                    <td class="text-end">
                      <button class="btn btn-light btn-sm" @click="signOutGmail">
                        <i class="far fa-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-for="integration in emailIntegrationConfigs">
                    <td class="text-capitalize">{{ integration.type }}</td>
                    <td>{{ integration.email }}</td>
                    <td class="text-capitalize">
                      <span
                        class="badge"
                        :class="
                          integration.status === 'active'
                            ? 'bg-primary'
                            : 'bg-light'
                        "
                      >
                        {{ integration.status }}
                      </span>
                    </td>
                    <td class="text-end">
                      <button class="btn btn-light btn-sm" @click="disconnectIntegration(integration)">
                        <i class="far fa-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              v-if="emailIntegrationConfigs.filter((item) => item.type === 'outlook' && item.status === 'active').length > 0"
              @click="syncEmails"
              class="btn btn-outline-primary btn-sm float-end"
            >
              Sync Emails
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import EmailSetup from "@/views/areas/settings/areas/integrations-partials/email-partials/EmailSetup";
export default {
  props: [],
  data() {
    return {
      loading: true,
      emailIntegrationConfigs: [],
      showAdd: false
    };
  },
  methods: {
    getEmailIntegrationConfigs() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/practitioners/fetch-email-integration",
          {}
        )
        .then(({ data }) => {
          this.emailIntegrationConfigs = data;
          this.loading = false;
        });
    },
    syncEmails() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/oauth/sync-emails", {
          event_sync: this.eventSync
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    disconnectIntegration(integration) {
      const confirmed = confirm(
        "Are you sure you wish to disconnect this integration? Emails from this integration will no longer be synced or visible in Swandoola."
      );

      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/integrations/disconnect-mail-service",
            {
              mail_config_id: integration.id
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
            this.getEmailIntegrationConfigs();
          });
      }
    },
    getEmailFromConfig(integration) {
      if (integration.config) {
        const config = JSON.parse(integration.config);
        return config.email ?? "";
      }
      return "";
    }
  },
  mounted() {
    this.$EventBus.$emit("refreshUser");
    this.getEmailIntegrationConfigs();
  },
  created() {
    this.initGmailSignin();
  },
  computed: {
    path() {
      return this.$stash.path;
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: {
    EmailSetup
  }
};
</script>

<style>
</style>
