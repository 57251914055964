<template>
  <div>
    <div class="row mb-4" v-if="!service">
      <div class="col-6 mx-auto text-center">
        <p>Select your email provider to get started:</p>
        <button class="btn btn-outline-primary w-100 mb-3 shadow-sm"
                v-if="!$stash.gmail.signedIn"
                @click="service='google'">
            <h6 class="d-inline">Google / Gmail</h6>
        </button>
        <button class="btn btn-outline-primary w-100 mb-3 shadow-sm" @click="goToOutlookSignin">
          <h6 class="d-inline">Microsoft / Outlook / Office365</h6>
        </button>
        <!-- <button class="btn btn-outline-primary w-100 shadow-sm" @click="service='smtp'">
          <h6 class="d-inline">SMTP / IMAP Configuration</h6>
        </button> -->
      </div>
    </div>

    <div v-if="service==='google'">
      <div class="row mb-3">
        <div class="col-6 mx-auto text-center">
          <google-setup :user="user" @closed="closed"></google-setup>
        </div>
      </div>
    </div>

    <div v-if="service==='smtp'">
      <smtp-setup :user="user"></smtp-setup>
    </div>
  </div>
</template>



<script>
import SmtpSetup from "./SmtpSetup";
import GoogleSetup from "./GoogleSetup";
export default {
  props: ["path", "user"],
  data() {
    return {
      service: null
    };
  },
  methods: {
    goToOutlookSignin(){
      this.$axios.get(process.env.VUE_APP_API_URL + "/oauth/outlook/signin")
          .then(({ data }) => {
            window.location = data;
          });
    },
    closed() {
      this.$emit("closed");
    }
  },
  computed: {
  },
  mounted() {
  },
  components: {
      GoogleSetup,
      SmtpSetup
  }
};
</script>

<style>
</style>
